import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Card from "../components/Card";

gsap.registerPlugin(ScrollTrigger);

export default function LandingPage() {
  const heading1 = useRef(null);
  const heading2 = useRef(null);
  const heading3 = useRef(null);
  const heading4 = useRef(null);
  const heading7 = useRef(null);

  useEffect(() => {
    const headings = [heading1, heading2, heading3, heading4];
    headings.forEach((heading) => {
      if (heading.current) {
        gsap.fromTo(
          heading.current,
          { scale: 0.8 },
          {
            scale: 1.1,
            duration: 1,
            ease: "power2.out",
            scrollTrigger: {
              trigger: heading.current,
              start: "top 80%",
              end: "top 20%",
              scrub: 1,
            },
          }
        );
      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Cardcontent = [
    {
      img: "/assets/img/schedule-img.png",
      altText: "First card",
      title: "Schedule a pickup",
    },
    {
      img: "/assets/img/pickup-img.png",
      altText: "Second card",
      title: "Pickup at your Address",
    },
    {
      img: "/assets/img/recieve-payment.png",
      altText: "Third card ",
      title: "Receive payment",
    },
  ];

  const Cardcontent2 = [
    { img: "/assets/img/service-img1.png", title: "Doorstep Pickup" },
    { img: "/assets/img/service-img2.png", title: "Accurate Valuations" },
    {
      img: "/assets/img/service-img3.png",
      title: "Trained & Verified Pickup Staff",
    },
    { img: "/assets/img/service-img4.png", title: "Eco-Friendly" },
    { img: "/assets/img/service-img5.png", title: "Waste Management" },
  ];

  useEffect(() => {
    gsap.fromTo(
      heading7.current,
      { scale: 0.8 },
      {
        scale: 1,
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: heading7.current,
          start: "top 80%",
          end: "top 20%",

          scrub: 1,
        },
      }
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const testimonials = [
    {
      name: "Shajahan",
      feedback:
        "The ScrapituP Team was really professional and their services the best that I have seen in my entire life. Could not have asked for anything more. Simply awesome ",
    },
    {
      name: "John Doe",
      feedback:
        "Fantastic service! They made the whole process so easy and stress-free. Highly recommended!",
    },
    {
      name: "Jane Smith",
      feedback:
        "I was impressed by their professionalism and efficiency. Great job, ScrapituP Team!",
    },
    {
      name: "Ahmed Khan",
      feedback:
        "Their attention to detail is unmatched. I couldn't be happier with the service I received!",
    },
    {
      name: "Emily Johnson",
      feedback:
        "ScrapituP offers excellent customer service and reliable solutions. Will use them again!",
    },
  ];

  return (
    <div>
      {/* Hero Section */}
      <div className="container-fluid overflow-hidden">
        <div className="row hero-img">
          <div className="col-12 mt-5">
            <h1 className="ps-4 hero-text">
              <span className="span-style">CLEAN UP</span> <br />
              <span className="span-style">
                YOUR SPACE, <br />
                CASH IN <br /> YOUR <span className="span-style">SCRAP!</span>
              </span>
            </h1>
          </div>
        </div>
      </div>

      {/* How it Works Section */}
      <div className="container mt-2">
        <h2 className="text-center my-3" ref={heading1}>
          How it <span className="green">works</span>
        </h2>
        <div className="row d-flex flex-lg-row">
          {Cardcontent.map((item, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <Card item={item} />
            </div>
          ))}
        </div>
      </div>

      {/* Mobile App Section */}
      <div className="download-app-section my-5" id="download-app">
        <div className="container position-relative">
          <div className="row d-flex justify-content-between">
            <div className="col-lg-4 col-md-12 col-sm-12 mt-10">
              <div className="content mt-5 text-center">
                <h2 className="my-3">ScrapItUp</h2>
                <h4 className="scrap-h5-text f-16">
                  India's No. 1 Scrap Pickup App Providing door-to-door free
                  pickup service of 40+ recyclables.
                </h4>
                <button className="btn-style my-3 mb-5 f-16">
                  Download Our App
                </button>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 mobile-app d-flex justify-content-center">
              <img
                className="img-fluid my-image"
                src="/assets/img/mobile-app-qr.png"
                alt="download-our-app"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Services Section */}
      <div className="container mt-5 overflow-hidden">
        <h2 className="text-center" ref={heading2}>
          Our <span className="green">Services</span>
        </h2>
        <p className="text-center">
          We are committed to enhancing waste management efficiency by
          delivering seamless and reliable solutions. With a focus on
          transparency and safety, we ensure a hassle-free experience, allowing
          you to manage scrap collection with ease and confidence.
        </p>
        <div className="row d-flex flex-lg-row justify-content-center">
          {Cardcontent2.map((item, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <Card item={item} />
            </div>
          ))}
        </div>
      </div>

      {/* Why Us Section */}
      <div className="parallax overflow-hidden" id="about">
        <div className="container my-3 heading">
          <h2 className="text-center pt-3" ref={heading3}>
            Why <span className="span-style">Us?</span>
          </h2>
          <h2 className="text-center pb-3 span-style f-20" ref={heading4}>
            Building a Sustainable Future with ScrapItUp
          </h2>
          <div className="row justify-content-center text-center">
            <div className="col-lg-4 col-md-12 col-sm-12 mb-5">
              <h4 className="mb-5 span-style">Driving the Circular Economy</h4>
              <h4 className="mb-5 span-style">
                Minimizing Environmental Impact
              </h4>
              <h4 className="span-style">Promoting Sustainability</h4>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 recycle-gif d-flex justify-content-center mb-5">
              <img
                src="/assets/img/recycle-img.png"
                className="img-fluid recycle-animation"
                alt="recycle"
              />
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 mb-5">
              <h4 className="mb-5 span-style">
                Innovative & Cost-Effective Solutions
              </h4>
              <h4 className="mb-5 span-style">Extending Product Life Cycles</h4>
              <h4 className="span-style">Closing the Waste Loop</h4>
            </div>
          </div>
        </div>
      </div>

      {/* House and Companies Section */}
      <div className="container overflow-hidden">
        <h3 className="text-center my-3" ref={heading4}>
          Unique Services for <span className="green">Homes and Companies</span>
        </h3>
        <p className="text-center">
          We specialize in providing seamless scrap collection services for both
          homes and businesses. Our solutions are designed to make waste
          management easy, efficient, and reliable, tailored to meet the
          specific needs of households and companies
        </p>

        <div className="card shadow p-3 rounded-5 card-style-long">
          <div className="row align-items-center">
            {/* Text Section */}
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h2 className="green">For Homes</h2>
              <h4 className="f-20">On-Demand Doorstep Pickups</h4>
              <p className="f-16">
                We make scrap collection simple by picking it up directly from
                your doorstep.
              </p>
              <h4 className="f-20">Accurate Digital Weighing</h4>
              <p className="f-16">
                Enjoy complete transparency with precise, digital weighing
                systems.
              </p>
              <h4 className="f-20">Safe & Verified Staff</h4>
              <p className="f-16">
                Our team is thoroughly trained and verified to ensure a safe
                experience for your household.
              </p>
            </div>

            {/* Image Section */}
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
              <img
                src="/assets/img/for-house.png"
                alt="for-house"
                className="img-fluid"
                style={{ maxWidth: "80%", height: "" }}
              />
            </div>
          </div>
        </div>

        {/* For Companies Section */}
        <div className="card shadow p-3 rounded-5 card-style-long my-5">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3 d-flex justify-content-center">
              <img
                src="/assets/img/for-companies.png"
                alt="for-house"
                className="img-fluid"
                style={{ maxWidth: "100%", height: "" }}
              />
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <h2 className="green">For Companies</h2>
              <h4 className="f-20">Commercial Scrap Collection</h4>
              <p className="f-16">
                Get on-demand scrap collection for your office or business, with
                flexible scheduling and reliable services.
              </p>
              <h4 className="f-20">Customizable Solutions</h4>
              <p className="f-16">
                We offer tailored solutions based on your business needs,
                whether it's bulk collection or recurring pickups.
              </p>
              <h4 className="f-20">Environmentally Friendly</h4>
              <p className="f-16">
                Help your business meet sustainability goals with our
                eco-friendly recycling solutions.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Trusted Companies */}
      <div>
        <div className="text-center trusted-by-leading-brands ">
          <h2 className="white-text p-2">Trusted by leading brands, </h2>
          <h2 className="white-text f-16">
            ScrapItUp helps you manage your scrap efficiently{" "}
          </h2>

          <div className="mt-4 white-bg">
            <div className="container my-2">
              <div className="row justify-content-evenly brand-bar align align-items-center">
                {/* <div className="col-auto">
                <img
                  className="p-2 brand-img"
                  src="/assets/img/image 15.png"
                  alt="brands"
                />
              </div>
              <div className="col-auto">
                <img
                  className="p-2 brand-img"
                  src="/assets/img/image 16.png"
                  alt="brands"
                />
              </div>
              <div className="col-auto">
                <img
                  className="p-2 brand-img"
                  src="/assets/img/image 17.png"
                  alt="brands"
                />
              </div>
              <div className="col-auto">
                <img
                  className="p-2 brand-img"
                  src="/assets/img/image 18.png"
                  alt="brands"
                />
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Client Say */}
      <div className="container-fluid">
        <h2 className="mt-3 text-center" ref={heading7}>
          What Clients <span className="green">Say</span>
        </h2>
        <p className="mb-5 text-center">
          Our clients appreciate the convenience of our doorstep pickups and the
          transparency offered by our digital weighing system. They trust our
          thoroughly vetted staff and commend the efficiency of our services,
          making scrap collection a seamless experience for both residential and
          commercial needs
        </p>
        <div className="row p-3 testimonials-row">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6 col-sm-12 mb-5 d-flex justify-content-center testimonial-col"
            >
              <div className="position-relative testimonials-card-container mb-4">
                <div className="background-layer"></div>
                <div className="card shadow p-3 rounded-5 testimonials-card">
                  <div className="card-body text-center">
                    <h4 className="card-title">{testimonial.name}</h4>
                    <p className="card-text">{testimonial.feedback}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Contact up map section */}

      <div className="" id="contact">
        <div className="container-fluid">
          <div className="row bg-green">
            <div className="col-lg-8 col-md-6 col-sm-12 d-flex align-items-center px-0">
              <iframe
                className="map-data"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d234700.73463944453!2d77.0940286602867!3d23.199418077284804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c428f8fd68fbd%3A0x2155716d572d4f8!2sBhopal%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1728464828095!5m2!1sen!2sin"
                width="100%"
                height="500"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 p-5 contact-us-card">
              <div className="card shadow-card border-0 rounded-5">
                <div className="card-header text-center ">
                  <h2>
                    Contact<span className="text-primary"> Us</span>
                  </h2>
                  <h4 className="f-20 ">Get in touch with us</h4>
                </div>
                <div className="card-body">
                  <h4 className="f-16 p-2">
                    <i className="fa fa-phone me-2" aria-hidden="true"></i>
                    +91-8982759856
                    <br />
                    <span style={{ marginLeft: "30px" }}>+91-8982759856</span>
                  </h4>
                  <h4 className="f-16 p-2">
                    <i className="fa fa-envelope-o me-2" aria-hidden="true"></i>
                    team@scrapitup.in
                  </h4>
                  <h4 className="f-16 p-2">
                    <i className="fa fa-map-marker me-2" aria-hidden="true"></i>
                    Scrapitup, near D-Mart, Vidya Nagar, Bhopal, Madhya Pradesh
                    462026
                  </h4>
                  <div className="mt-4">
                    <a href="#" className="text-dark mx-3">
                      <i
                        className="fa fa-facebook"
                        style={{ fontSize: "23px" }}
                      ></i>
                    </a>
                    <a href="#" className="text-dark mx-3">
                      <i
                        className="fa fa-instagram"
                        style={{ fontSize: "23px" }}
                      ></i>
                    </a>
                    <a href="#" className="text-dark mx-3">
                      <i
                        className="fa fa-twitter"
                        style={{ fontSize: "23px" }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
