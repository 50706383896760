import React, { useEffect } from "react";


const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container privacy-container my-5">
      <div className="row">
        <div className="col-12 mb-4">
          <h1 className="privacy-heading text-center green mb-3">Privacy Policy</h1>
          <span className="green-border"></span>
        </div>
      </div>

      {/* Information Section */}
      <div className="row mb-4">
        <div className="col-12">
          <h4 className="privacy-subheading green">1. Information</h4>
          <p className="privacy-text" style={{ fontSize: "0.9rem" }}>
            Scrap It Up is a platform that allows to manage the selling of scrap
            materials, including paper, plastic, metal, and electronics. We
            value your privacy and are committed to safeguarding your personal
            information. Our Privacy Policy applies to all the products and
            services we offer, outlining how we collect, use, and disclose
            personal details such as name, address, location, income, and more.
            Users can access our services through our mobile application,
            website, or by contacting our customer support team. By using our
            website, you acknowledge and agree to our Privacy Policy and Terms
            and Conditions.
          </p>
        </div>
      </div>

      {/* Collection of Information */}
      <div className="row mb-4">
        <div className="col-12">
          <h4 className="privacy-subheading green">
            2. Collection of Information
          </h4>
          <ul className="privacy-list">
            <li style={{ fontSize: "0.9rem" }}>
              At Scrap It Up, we prioritize your privacy and ensure that we only
              collect the information necessary to provide our services and
              safeguard your account.
            </li>
            <li style={{ fontSize: "0.9rem" }}>
              During registration, we may collect personal details such as your
              name, address, mobile number, phone number, account number, IFSC
              code, bank name, branch name, and your location when using our
              Website or Mobile Application.
            </li>
            <li style={{ fontSize: "0.9rem" }}>
              We also track and maintain records of user activities which are
              being performed on our platform.
            </li>
            <li style={{ fontSize: "0.9rem" }}>
              To complete the registration process, users are required to
              provide essential information, which may include business details
              (e.g., registration and account information) or personal details
              (e.g., name, contact number, and address). Without this mandatory
              information, we may be unable to finalize the registration or
              provide our services.
            </li>
          </ul>
        </div>
      </div>

      {/* Use of Personal Data */}
      <div className="row mb-4">
        <div className="col-12">
          <h4 className="privacy-subheading green">3. Use of Personal Data</h4>
          <ul className="privacy-list" style={{ fontSize: "0.9rem" }}>
            <li>Identity Verification: To confirm the identity of users.</li>
            <li style={{ fontSize: "0.9rem" }}>
              Eligibility Confirmation: To determine if users meet the
              requirements to register on our platform.
            </li>
            <li style={{ fontSize: "0.9rem" }}>
              Registration Management: To create a user ID, maintain, and manage
              your account on our platform.
            </li>
            <li style={{ fontSize: "0.9rem" }}>
              Customer Support: To address your queries, feedback, claims, or
              disputes effectively.
            </li>
            <li style={{ fontSize: "0.9rem" }}>
              Facilitating Transactions: To enable communication between buyers
              and sellers on the platform.
            </li>
            <li style={{ fontSize: "0.9rem" }}>
              Marketing Communications: We may use your name, phone number,
              address, and email to send notices, surveys, product alerts, and
              other marketing materials related to our services. Users can opt
              in to consent to marketing communications by selecting the
              checkbox during registration. You may opt out at any time to
              withdraw your consent and stop receiving marketing communications.
            </li>
            <li style={{ fontSize: "0.9rem" }}>
              Additionally, we may disclose personal data if required for the
              above purposes, by law, or in connection with any claims or
              potential claims against us.
            </li>
          </ul>
        </div>
      </div>

      {/* Disclosure of Personal Data */}
      <div className="row mb-4">
        <div className="col-12">
          <h4 className="privacy-subheading green">
            4. Disclosure of Personal Data
          </h4>
          <p className="privacy-text" style={{ fontSize: "0.9rem" }}>
            We may disclose and handle personal data in the following ways:
          </p>
          <ul className="privacy-list" style={{ fontSize: "0.9rem" }}>
            <li>
              Disclosure and Transfer: Personal data may be shared with
              professional advisers, law enforcement agencies, insurers,
              government bodies, or other relevant organizations when necessary.
            </li>
            <li>
              Retention and Access: Personal data provided by users will be
              retained by us and may be accessed by our employees, service
              providers, or third parties involved in delivering our services.
            </li>
            <li>
              Account Information Sharing: User account details may be shared
              with banks to facilitate transactions or assess creditworthiness.
              In this process, account information may also be shared with
              credit agencies or financial institutions.
            </li>
            <li>
              Security Measures: We have implemented security controls and
              procedures to protect personal data and account information from
              unauthorized access, data theft, or misuse. While these measures
              aim to ensure minimum-security standards, they cannot guarantee
              complete protection against all risks of data loss or theft.
            </li>
          </ul>
        </div>
      </div>

      {/* Cookies Section */}
      <div className="row mb-4">
        <div className="col-12">
          <h4 className="privacy-subheading green">5. Cookies</h4>
          <p className="privacy-text" style={{ fontSize: "0.9rem" }}>
            We use "cookies" to store specific user information and track visits
            to our website. A "cookie" is a small data file sent to the user's
            browser and saved on their device's hard drive. Cookies are only
            sent when the user accesses the website from a specific device. If
            cookies are not disabled or deleted, our web servers will recognize
            the user on subsequent visits, allowing us to understand their usage
            patterns and preferences.
          </p>
          <ul className="privacy-list" style={{ fontSize: "0.9rem" }}>
            <li>
              Access users’ Registration or Account Information, so they don’t
              need to re-enter it repeatedly.
            </li>
            <li>
              Assist our partners in tracking user visits and processing bids.
            </li>
            <li>Monitor user activity and progress on the website.</li>
          </ul>
          <p className="privacy-text" style={{ fontSize: "0.9rem" }}>
            Users can control how cookies are handled by adjusting their browser
            settings. Options include accepting all cookies, being notified when
            a cookie is sent, or rejecting all cookies. However, disabling
            cookies may require users to enter their information more frequently
            and could limit access to certain features of the website.
          </p>
        </div>
      </div>

      {/* Minors */}
      <div className="row mb-4">
        <div className="col-12">
          <h4 className="privacy-subheading green">6. Minors</h4>
          <p className="privacy-text" style={{ fontSize: "0.9rem" }}>
            Our website and its content are not designed for minors (individuals
            under 18 years of age), and we do not knowingly offer our products
            or services to them. However, we cannot verify the age of every user
            who accesses our site. If a minor provides us with personal
            information without the consent of a parent or guardian, the parent
            or guardian can contact our Legal Department at the email address
            below to request the removal of such information.
          </p>
          <p className="privacy-text" style={{ fontSize: "0.9rem" }}>
            Email: <a href="mailto:team@scrapitup.com">team@scrapitup.com</a>
          </p>{" "}
        </div>
      </div>

      {/* Security Measures */}
      <div className="row mb-4">
        <div className="col-12">
          <h4 className="privacy-subheading green">7. Security Measures</h4>
          <p className="privacy-text" style={{ fontSize: "0.9rem" }}>
            We implement reasonable security measures to prevent unauthorized
            access, maintain data accuracy, and ensure the proper use of the
            information we manage.
          </p>
          <ul className="privacy-list" style={{ fontSize: "0.9rem" }}>
            <li>
              We advise you to keep your password confidential and not share it
              with anyone.
            </li>
            <li>
              Our team will never request your password through unsolicited
              phone calls or emails.
            </li>
            <li>
              If you use a shared computer, avoid saving your login details
              (e.g., user ID and password) on the device. Additionally, always
              sign out of your account and close your browser after completing
              your session to safeguard your information.
            </li>
          </ul>
        </div>
      </div>

      {/* Changes to Privacy Policy */}
      <div className="row mb-4">
        <div className="col-12">
          <h4 className="privacy-subheading green">
            8. Changes to this Privacy Policy
          </h4>
          <p className="privacy-text" style={{ fontSize: "0.9rem" }}>
            We will notify you of any updates to this Privacy Policy by posting
            the revised version on our website. By continuing to use our
            services, you agree that all information we collect and manage will
            be governed by the latest version of the Privacy Policy.
          </p>
        </div>
      </div>

      {/* Your Feedback */}
      <div className="row mb-4">
        <div className="col-12">
          <h4 className="privacy-subheading green">9. Your Feedback</h4>
          <p className="privacy-text" style={{ fontSize: "0.9rem" }}>
            We value your feedback on our Privacy Policy and the services we
            offer. You are encouraged to share your comments and suggestions
            with us via mail.
          </p>
        </div>
      </div>

      {/* Grievance Officer */}
      <div className="row">
        <div className="col-12">
          <h4 className="privacy-subheading green">10. Grievance Officer</h4>
          <p className="privacy-text" style={{ fontSize: "0.9rem" }}>
            As per the Information Technology Act, 2000 and its associated
            rules, the contact details of our Grievance Officer are provided
            below. If you have any concerns or issues regarding your information
            with Scrap It Up, please reach out to the Grievance Officer. They
            will address your grievances promptly.
          </p>
          <p className="privacy-text" style={{ fontSize: "0.9rem" }}>
            Name: Nizam Sayyed & Sahil Khan
          </p>
          <p className="privacy-text" style={{ fontSize: "0.9rem" }}>
            Email: <a href="mailto:team@scrapitup.com">team@scrapitup.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
