import React from "react";

export default function Card({ item }) {
  console.log("props:", item);
  return (
    <div class="container">
      <div class="card card-style shadow p-3 rounded-5 ">
        <div class="d-flex justify-content-center">
          <img
            className="card-img-style card-img"
            src={item.img}
            width=" 100%"
            height="150px"
          />
        </div>
        <h3 className="text-center mt-2 f-20">{item.title}</h3>
      </div>
    </div>
  );
}
